@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@400;600;700&family=Playfair+Display:wght@400;600;700&display=swap');

* {
  box-sizing: border-box;
}

.App-header {
  /* background-color: #282c34; */
  background-color: rgba(220, 226, 255, 0);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
